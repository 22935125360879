import React from 'react';
import styled from '@emotion/styled';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import { mq } from 'style/variables';

type TextProps = {
  isDiv?: boolean;
} & JSX.IntrinsicElements['p'];

const Container = styled.p<TextProps>`
  font-family: ${fonts.jaGothic};
  font-weight: 500;
  ${text(18, 38 / 18)}
  letter-spacing: 0.04em;
  [data-lang='en'] & {
    font-family: ${fonts.enSans};
    letter-spacing: 0em;
    line-height: 1.8em;
  }
  a {
    text-decoration: underline;
    ${mq.onlypc} {
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
`;

export const Text: React.FC<TextProps> = props => {
  const TagName = Container.withComponent(props.isDiv ? 'div' : 'p');
  return <TagName {...props}>{props.children}</TagName>;
};
